import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const container = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '14px',
  height: '50px',
  cursor: 'pointer',
  [mq(MediaQuery.Mobile)]: {
    flexDirection: 'column-reverse',
    gap: '5px',
    height: '100%',
  },
});

export const logo = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const text = css({
  marginLeft: '-20px',
  [mq(MediaQuery.Mobile)]: {
    marginLeft: '-12px',
  },
});

export const badge = css({
  alignSelf: 'flex-start',
  marginBottom: '20px',
  [mq(MediaQuery.Mobile)]: {
    marginBottom: '0',
  },
});
