import React from 'react';
import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  variant: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
}

const ActionButton = ({
  children,
  onClick,
  variant,
  disabled = false,
}: ActionButtonProps) => {
  const buttonClass = `${styles.button} ${styles[variant]} ${
    disabled ? styles.disabled : ''
  }`;

  return (
    <button
      disabled={disabled}
      className={buttonClass}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};

export default ActionButton;
