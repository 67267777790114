import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { NerdbordLogoIcon } from '../icons/NerdbordLogoIcon';
import {
  NerdbordLogoText,
  TextColor,
} from '../icons/NerdbordLogoText';
import { OpenAiLogo } from '../OpenAiLogo/OpenAiLogo';
import * as styles from './LogoWithBadge.styles';

interface LogoWithBadgeProps {
  logoImagePath: string
  textColor: TextColor;
  onClick?: () => void;
}

export const LogoWithBadge = ({
  logoImagePath,
  textColor,
  onClick,
}: LogoWithBadgeProps) => {
  const { isMobile } = useMediaQuery();
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.logo}>
        <NerdbordLogoIcon size={isMobile ? 'small' : 'big'} />
        <div className={styles.text}>
          <NerdbordLogoText
            size={isMobile ? 'small' : 'big'}
            color={textColor}
          />
        </div>
      </div>
      <div className={styles.badge}>
        <OpenAiLogo
          imagePath={logoImagePath}
        />
      </div>
    </div>
  );
};
