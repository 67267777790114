import { useMediaQuery } from '@/hooks/useMediaQuery';

export enum TextColor {
  Black = '#222225',
  White = '#FFFFFF',
}

interface NerdbordLogoTextProps {
  color: TextColor;
  size: 'big' | 'small'
}

export const NerdbordLogoText = ({
  color, size
}: NerdbordLogoTextProps) => {
  const { isMobile, isDesktop } = useMediaQuery();

  const desktopSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="22"
      viewBox="0 0 117 22"
      fill="none"
    >
      <path
        d="M17.0657 0V20.7668H13.7195L3.93433 6.62144H3.76195V20.7668H0V0H3.36649L13.1415 14.1555H13.324V0H17.0657Z"
        fill={color}
      />
      <path
        d="M26.47 21.071C24.9085 21.071 23.5598 20.7465 22.4242 20.0975C21.2952 19.4418 20.4266 18.5157 19.8182 17.3192C19.2098 16.1159 18.9056 14.6997 18.9056 13.0705C18.9056 11.4684 19.2098 10.0623 19.8182 8.85224C20.4333 7.63544 21.2919 6.68904 22.3937 6.01303C23.4956 5.33027 24.7902 4.98889 26.2774 4.98889C27.2373 4.98889 28.1431 5.14437 28.9949 5.45533C29.8534 5.75953 30.6105 6.23273 31.2663 6.87494C31.9287 7.51714 32.4493 8.3351 32.8278 9.32882C33.2064 10.3158 33.3957 11.492 33.3957 12.8575V13.9831H20.6294V11.5089H29.8771C29.8703 10.8059 29.7182 10.1806 29.4208 9.63302C29.1233 9.0787 28.7076 8.64268 28.1736 8.32496C27.6463 8.00724 27.0311 7.84838 26.3281 7.84838C25.5777 7.84838 24.9186 8.0309 24.3508 8.39594C23.7829 8.75422 23.3401 9.22742 23.0224 9.81554C22.7115 10.3969 22.5526 11.0357 22.5458 11.732V13.8918C22.5458 14.7977 22.7115 15.5751 23.0427 16.224C23.3739 16.8662 23.837 17.3597 24.4319 17.7045C25.0268 18.0425 25.723 18.2115 26.5207 18.2115C27.0548 18.2115 27.5381 18.1371 27.9708 17.9884C28.4034 17.8329 28.7786 17.6065 29.0963 17.309C29.414 17.0116 29.654 16.6432 29.8162 16.2038L33.2436 16.5891C33.0272 17.4949 32.6149 18.2858 32.0065 18.9618C31.4048 19.6311 30.6342 20.1516 29.6946 20.5234C28.7549 20.8884 27.6801 21.071 26.47 21.071Z"
        fill={color}
      />
      <path
        d="M35.0733 20.7668V5.19169H38.6325V7.78754H38.7947C39.0786 6.88846 39.5654 6.19556 40.2549 5.70883C40.9512 5.21535 41.7455 4.96861 42.6378 4.96861C42.8406 4.96861 43.0671 4.97875 43.3172 4.99903C43.5741 5.01255 43.787 5.03621 43.956 5.07001V8.44664C43.8005 8.39256 43.5538 8.34524 43.2158 8.30468C42.8845 8.25736 42.5634 8.2337 42.2525 8.2337C41.5832 8.2337 40.9816 8.37904 40.4475 8.66972C39.9203 8.95364 39.5045 9.3491 39.2003 9.8561C38.8961 10.3631 38.744 10.9478 38.744 11.6103V20.7668H35.0733Z"
        fill={color}
      />
      <path
        d="M50.1054 21.0406C48.8818 21.0406 47.7867 20.7262 46.82 20.0975C45.8534 19.4688 45.0895 18.5562 44.5284 17.3597C43.9673 16.1632 43.6868 14.7098 43.6868 12.9995C43.6868 11.2689 43.9707 9.80878 44.5385 8.61902C45.1131 7.4225 45.8872 6.52004 46.8606 5.91163C47.834 5.29647 48.919 4.98889 50.1156 4.98889C51.0282 4.98889 51.7785 5.14437 52.3666 5.45533C52.9548 5.75953 53.4212 6.12795 53.766 6.5606C54.1107 6.98648 54.3777 7.3887 54.567 7.76726H54.7191V0H58.4V20.7668H54.7901V18.3129H54.567C54.3777 18.6914 54.104 19.0937 53.7457 19.5195C53.3874 19.9387 52.9142 20.2969 52.3261 20.5944C51.738 20.8918 50.9977 21.0406 50.1054 21.0406ZM51.1296 18.029C51.907 18.029 52.5694 17.8194 53.117 17.4003C53.6646 16.9744 54.0803 16.3829 54.3642 15.6258C54.6481 14.8687 54.7901 13.9865 54.7901 12.9792C54.7901 11.972 54.6481 11.0966 54.3642 10.353C54.0871 9.60936 53.6747 9.03138 53.1271 8.61902C52.5863 8.20666 51.9205 8.00048 51.1296 8.00048C50.3116 8.00048 49.6288 8.21342 49.0813 8.6393C48.5337 9.06518 48.1213 9.6533 47.8442 10.4037C47.567 11.154 47.4284 12.0125 47.4284 12.9792C47.4284 13.9527 47.567 14.8213 47.8442 15.5852C48.1281 16.3423 48.5438 16.9406 49.0914 17.38C49.6457 17.8126 50.3251 18.029 51.1296 18.029Z"
        fill={color}
      />
      <path
        d="M61.046 20.7668V0H64.7167V7.76726H64.8688C65.0581 7.3887 65.3251 6.98648 65.6698 6.5606C66.0146 6.12795 66.481 5.75953 67.0692 5.45533C67.6573 5.14437 68.4076 4.98889 69.3202 4.98889C70.5235 4.98889 71.6085 5.29647 72.5752 5.91163C73.5486 6.52004 74.3193 7.4225 74.8871 8.61902C75.4617 9.80878 75.749 11.2689 75.749 12.9995C75.749 14.7098 75.4685 16.1632 74.9074 17.3597C74.3463 18.5562 73.5824 19.4688 72.6158 20.0975C71.6491 20.7262 70.5539 21.0406 69.3304 21.0406C68.4381 21.0406 67.6978 20.8918 67.1097 20.5944C66.5216 20.2969 66.0484 19.9387 65.6901 19.5195C65.3386 19.0937 65.0648 18.6914 64.8688 18.3129H64.6558V20.7668H61.046ZM64.6457 12.9792C64.6457 13.9865 64.7877 14.8687 65.0716 15.6258C65.3623 16.3829 65.778 16.9744 66.3188 17.4003C66.8664 17.8194 67.5288 18.029 68.3063 18.029C69.1175 18.029 69.7968 17.8126 70.3444 17.38C70.892 16.9406 71.3043 16.3423 71.5815 15.5852C71.8654 14.8213 72.0074 13.9527 72.0074 12.9792C72.0074 12.0125 71.8688 11.154 71.5916 10.4037C71.3145 9.6533 70.9021 9.06518 70.3545 8.6393C69.807 8.21342 69.1242 8.00048 68.3063 8.00048C67.5221 8.00048 66.8562 8.20666 66.3087 8.61902C65.7611 9.03138 65.3454 9.60936 65.0614 10.353C64.7843 11.0966 64.6457 11.972 64.6457 12.9792Z"
        fill={color}
      />
      <path
        d="M84.2357 21.071C82.7147 21.071 81.3965 20.7363 80.2811 20.0671C79.1657 19.3979 78.3004 18.4616 77.6853 17.2583C77.0769 16.055 76.7727 14.649 76.7727 13.0401C76.7727 11.4312 77.0769 10.0217 77.6853 8.81168C78.3004 7.60164 79.1657 6.662 80.2811 5.99275C81.3965 5.32351 82.7147 4.98889 84.2357 4.98889C85.7567 4.98889 87.0749 5.32351 88.1903 5.99275C89.3057 6.662 90.1676 7.60164 90.776 8.81168C91.3912 10.0217 91.6988 11.4312 91.6988 13.0401C91.6988 14.649 91.3912 16.055 90.776 17.2583C90.1676 18.4616 89.3057 19.3979 88.1903 20.0671C87.0749 20.7363 85.7567 21.071 84.2357 21.071ZM84.256 18.1304C85.0807 18.1304 85.7702 17.9039 86.3246 17.451C86.8789 16.9913 87.2912 16.3761 87.5616 15.6055C87.8388 14.8349 87.9774 13.9763 87.9774 13.0299C87.9774 12.0768 87.8388 11.2149 87.5616 10.4442C87.2912 9.66682 86.8789 9.04828 86.3246 8.5886C85.7702 8.12892 85.0807 7.89908 84.256 7.89908C83.411 7.89908 82.708 8.12892 82.1469 8.5886C81.5926 9.04828 81.1768 9.66682 80.8997 10.4442C80.6292 11.2149 80.494 12.0768 80.494 13.0299C80.494 13.9763 80.6292 14.8349 80.8997 15.6055C81.1768 16.3761 81.5926 16.9913 82.1469 17.451C82.708 17.9039 83.411 18.1304 84.256 18.1304Z"
        fill={color}
      />
      <path
        d="M93.3866 20.7668V5.19169H96.9457V7.78754H97.108C97.3919 6.88846 97.8786 6.19556 98.5681 5.70883C99.2644 5.21535 100.059 4.96861 100.951 4.96861C101.154 4.96861 101.38 4.97875 101.63 4.99903C101.887 5.01255 102.1 5.03621 102.269 5.07001V8.44664C102.114 8.39256 101.867 8.34524 101.529 8.30468C101.198 8.25736 100.877 8.2337 100.566 8.2337C99.8965 8.2337 99.2948 8.37904 98.7608 8.66972C98.2335 8.95364 97.8178 9.3491 97.5136 9.8561C97.2094 10.3631 97.0573 10.9478 97.0573 11.6103V20.7668H93.3866Z"
        fill={color}
      />
      <path
        d="M108.419 21.0406C107.195 21.0406 106.1 20.7262 105.133 20.0975C104.167 19.4688 103.403 18.5562 102.842 17.3597C102.281 16.1632 102 14.7098 102 12.9995C102 11.2689 102.284 9.80878 102.852 8.61902C103.426 7.4225 104.2 6.52004 105.174 5.91163C106.147 5.29647 107.232 4.98889 108.429 4.98889C109.341 4.98889 110.092 5.14437 110.68 5.45533C111.268 5.75953 111.734 6.12795 112.079 6.5606C112.424 6.98648 112.691 7.3887 112.88 7.76726H113.032V0H116.713V20.7668H113.103V18.3129H112.88C112.691 18.6914 112.417 19.0937 112.059 19.5195C111.701 19.9387 111.227 20.2969 110.639 20.5944C110.051 20.8918 109.311 21.0406 108.419 21.0406ZM109.443 18.029C110.22 18.029 110.883 17.8194 111.43 17.4003C111.978 16.9744 112.394 16.3829 112.677 15.6258C112.961 14.8687 113.103 13.9865 113.103 12.9792C113.103 11.972 112.961 11.0966 112.677 10.353C112.4 9.60936 111.988 9.03138 111.44 8.61902C110.9 8.20666 110.234 8.00048 109.443 8.00048C108.625 8.00048 107.942 8.21342 107.395 8.6393C106.847 9.06518 106.435 9.6533 106.157 10.4037C105.88 11.154 105.742 12.0125 105.742 12.9792C105.742 13.9527 105.88 14.8213 106.157 15.5852C106.441 16.3423 106.857 16.9406 107.405 17.38C107.959 17.8126 108.638 18.029 109.443 18.029Z"
        fill={color}
      />
    </svg>
  );

  const mobileSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="13"
      viewBox="0 0 68 13"
      fill="none"
    >
      <path
        d="M10.282 0.748291V12.6565H8.36319L2.75214 4.5452H2.6533V12.6565H0.496094V0.748291H2.42653L8.03176 8.86542H8.13643V0.748291H10.282Z"
        fill={color}
      />
      <path
        d="M15.6747 12.8309C14.7793 12.8309 14.0059 12.6449 13.3547 12.2727C12.7073 11.8967 12.2092 11.3657 11.8604 10.6796C11.5115 9.98956 11.3371 9.17746 11.3371 8.24326C11.3371 7.32456 11.5115 6.51827 11.8604 5.8244C12.2131 5.12665 12.7054 4.58396 13.3373 4.19632C13.9691 3.80481 14.7114 3.60905 15.5642 3.60905C16.1147 3.60905 16.6341 3.69821 17.1225 3.87652C17.6148 4.05096 18.049 4.32231 18.425 4.69056C18.8049 5.05882 19.1034 5.52786 19.3204 6.09769C19.5375 6.66364 19.6461 7.33812 19.6461 8.12115V8.76657H12.3255V7.34782H17.6284C17.6245 6.94467 17.5373 6.58611 17.3667 6.27212C17.1962 5.95426 16.9578 5.70423 16.6516 5.52204C16.3492 5.33985 15.9965 5.24876 15.5933 5.24876C15.163 5.24876 14.7851 5.35342 14.4595 5.56275C14.1339 5.76819 13.88 6.03954 13.6978 6.37678C13.5194 6.71015 13.4284 7.07647 13.4245 7.47574V8.71424C13.4245 9.23367 13.5194 9.67945 13.7094 10.0516C13.8993 10.4198 14.1649 10.7028 14.506 10.9005C14.8471 11.0943 15.2464 11.1912 15.7038 11.1912C16.01 11.1912 16.2872 11.1486 16.5353 11.0633C16.7834 10.9742 16.9985 10.8443 17.1807 10.6737C17.3629 10.5032 17.5005 10.2919 17.5935 10.04L19.5588 10.2609C19.4348 10.7803 19.1983 11.2339 18.8495 11.6215C18.5045 12.0053 18.0626 12.3038 17.5237 12.517C16.9849 12.7263 16.3686 12.8309 15.6747 12.8309Z"
        fill={color}
      />
      <path
        d="M20.6081 12.6565V3.72535H22.649V5.21387H22.742C22.9048 4.69831 23.1839 4.30099 23.5793 4.02189C23.9786 3.73891 24.4341 3.59743 24.9457 3.59743C25.062 3.59743 25.1919 3.60324 25.3353 3.61487C25.4826 3.62262 25.6047 3.63619 25.7016 3.65557V5.59182C25.6125 5.56081 25.471 5.53367 25.2772 5.51041C25.0872 5.48328 24.9031 5.46971 24.7248 5.46971C24.341 5.46971 23.996 5.55305 23.6898 5.71974C23.3874 5.88255 23.149 6.10931 22.9746 6.40004C22.8002 6.69077 22.7129 7.02608 22.7129 7.40596V12.6565H20.6081Z"
        fill={color}
      />
      <path
        d="M29.2279 12.8135C28.5262 12.8135 27.8983 12.6333 27.344 12.2727C26.7896 11.9122 26.3516 11.3889 26.0299 10.7028C25.7081 10.0167 25.5473 9.18328 25.5473 8.20256C25.5473 7.2102 25.7101 6.37291 26.0357 5.69067C26.3652 5.00455 26.809 4.48705 27.3672 4.13818C27.9254 3.78543 28.5476 3.60905 29.2337 3.60905C29.757 3.60905 30.1873 3.69821 30.5245 3.87652C30.8618 4.05096 31.1292 4.26222 31.3269 4.51031C31.5246 4.75452 31.6777 4.98517 31.7863 5.20224H31.8735V0.748291H33.9842V12.6565H31.9142V11.2494H31.7863C31.6777 11.4665 31.5207 11.6971 31.3153 11.9413C31.1098 12.1817 30.8385 12.3871 30.5013 12.5577C30.164 12.7282 29.7395 12.8135 29.2279 12.8135ZM29.8151 11.0866C30.2609 11.0866 30.6408 10.9664 30.9548 10.7261C31.2688 10.4819 31.5072 10.1427 31.67 9.70853C31.8328 9.27437 31.9142 8.76851 31.9142 8.19093C31.9142 7.61335 31.8328 7.11136 31.67 6.68496C31.5111 6.25855 31.2746 5.92712 30.9606 5.69067C30.6505 5.45421 30.2687 5.33598 29.8151 5.33598C29.3461 5.33598 28.9546 5.45808 28.6406 5.7023C28.3266 5.94651 28.0902 6.28375 27.9312 6.71403C27.7723 7.14431 27.6928 7.63661 27.6928 8.19093C27.6928 8.74912 27.7723 9.24724 27.9312 9.68527C28.094 10.1194 28.3324 10.4625 28.6464 10.7144C28.9643 10.9625 29.3538 11.0866 29.8151 11.0866Z"
        fill={color}
      />
      <path
        d="M35.5015 12.6565V0.748291H37.6064V5.20224H37.6936C37.8021 4.98517 37.9552 4.75452 38.1529 4.51031C38.3506 4.26222 38.6181 4.05096 38.9553 3.87652C39.2926 3.69821 39.7229 3.60905 40.2462 3.60905C40.9362 3.60905 41.5583 3.78543 42.1126 4.13818C42.6708 4.48705 43.1127 5.00455 43.4384 5.69067C43.7679 6.37291 43.9326 7.2102 43.9326 8.20256C43.9326 9.18328 43.7717 10.0167 43.45 10.7028C43.1283 11.3889 42.6902 11.9122 42.1359 12.2727C41.5816 12.6333 40.9536 12.8135 40.252 12.8135C39.7403 12.8135 39.3158 12.7282 38.9786 12.5577C38.6413 12.3871 38.37 12.1817 38.1646 11.9413C37.963 11.6971 37.806 11.4665 37.6936 11.2494H37.5715V12.6565H35.5015ZM37.5657 8.19093C37.5657 8.76851 37.6471 9.27437 37.8099 9.70853C37.9766 10.1427 38.2149 10.4819 38.5251 10.7261C38.839 10.9664 39.2189 11.0866 39.6647 11.0866C40.1299 11.0866 40.5195 10.9625 40.8334 10.7144C41.1474 10.4625 41.3839 10.1194 41.5428 9.68527C41.7056 9.24724 41.787 8.74912 41.787 8.19093C41.787 7.63661 41.7076 7.14431 41.5486 6.71403C41.3897 6.28375 41.1532 5.94651 40.8393 5.7023C40.5253 5.45808 40.1338 5.33598 39.6647 5.33598C39.2151 5.33598 38.8332 5.45421 38.5192 5.69067C38.2053 5.92712 37.9669 6.25855 37.8041 6.68496C37.6451 7.11136 37.5657 7.61335 37.5657 8.19093Z"
        fill={color}
      />
      <path
        d="M48.7991 12.8309C47.9269 12.8309 47.171 12.6391 46.5314 12.2553C45.8918 11.8715 45.3956 11.3347 45.0429 10.6447C44.694 9.95468 44.5196 9.14839 44.5196 8.22581C44.5196 7.30324 44.694 6.49501 45.0429 5.80114C45.3956 5.10727 45.8918 4.56846 46.5314 4.1847C47.171 3.80093 47.9269 3.60905 48.7991 3.60905C49.6713 3.60905 50.4272 3.80093 51.0668 4.1847C51.7064 4.56846 52.2006 5.10727 52.5495 5.80114C52.9022 6.49501 53.0786 7.30324 53.0786 8.22581C53.0786 9.14839 52.9022 9.95468 52.5495 10.6447C52.2006 11.3347 51.7064 11.8715 51.0668 12.2553C50.4272 12.6391 49.6713 12.8309 48.7991 12.8309ZM48.8107 11.1447C49.2836 11.1447 49.679 11.0149 49.9969 10.7551C50.3148 10.4916 50.5512 10.1388 50.7063 9.6969C50.8652 9.25499 50.9447 8.76269 50.9447 8.22C50.9447 7.67343 50.8652 7.17919 50.7063 6.73729C50.5512 6.2915 50.3148 5.93682 49.9969 5.67322C49.679 5.40963 49.2836 5.27783 48.8107 5.27783C48.3262 5.27783 47.923 5.40963 47.6013 5.67322C47.2834 5.93682 47.045 6.2915 46.8861 6.73729C46.731 7.17919 46.6535 7.67343 46.6535 8.22C46.6535 8.76269 46.731 9.25499 46.8861 9.6969C47.045 10.1388 47.2834 10.4916 47.6013 10.7551C47.923 11.0149 48.3262 11.1447 48.8107 11.1447Z"
        fill={color}
      />
      <path
        d="M54.0464 12.6565V3.72535H56.0873V5.21387H56.1804C56.3432 4.69831 56.6223 4.30099 57.0177 4.02189C57.417 3.73891 57.8724 3.59743 58.3841 3.59743C58.5004 3.59743 58.6303 3.60324 58.7737 3.61487C58.921 3.62262 59.0431 3.63619 59.14 3.65557V5.59182C59.0508 5.56081 58.9094 5.53367 58.7155 5.51041C58.5256 5.48328 58.3415 5.46971 58.1632 5.46971C57.7794 5.46971 57.4344 5.55305 57.1282 5.71974C56.8258 5.88255 56.5874 6.10931 56.413 6.40004C56.2385 6.69077 56.1513 7.02608 56.1513 7.40596V12.6565H54.0464Z"
        fill={color}
      />
      <path
        d="M62.6662 12.8135C61.9646 12.8135 61.3366 12.6333 60.7823 12.2727C60.228 11.9122 59.79 11.3889 59.4682 10.7028C59.1465 10.0167 58.9856 9.18328 58.9856 8.20256C58.9856 7.2102 59.1484 6.37291 59.474 5.69067C59.8035 5.00455 60.2474 4.48705 60.8056 4.13818C61.3638 3.78543 61.9859 3.60905 62.6721 3.60905C63.1954 3.60905 63.6256 3.69821 63.9629 3.87652C64.3001 4.05096 64.5676 4.26222 64.7653 4.51031C64.963 4.75452 65.1161 4.98517 65.2246 5.20224H65.3119V0.748291H67.4225V12.6565H65.3526V11.2494H65.2246C65.1161 11.4665 64.9591 11.6971 64.7537 11.9413C64.5482 12.1817 64.2769 12.3871 63.9396 12.5577C63.6024 12.7282 63.1779 12.8135 62.6662 12.8135ZM63.2535 11.0866C63.6993 11.0866 64.0792 10.9664 64.3932 10.7261C64.7071 10.4819 64.9455 10.1427 65.1084 9.70853C65.2712 9.27437 65.3526 8.76851 65.3526 8.19093C65.3526 7.61335 65.2712 7.11136 65.1084 6.68496C64.9494 6.25855 64.713 5.92712 64.399 5.69067C64.0889 5.45421 63.707 5.33598 63.2535 5.33598C62.7845 5.33598 62.393 5.45808 62.079 5.7023C61.765 5.94651 61.5285 6.28375 61.3696 6.71403C61.2107 7.14431 61.1312 7.63661 61.1312 8.19093C61.1312 8.74912 61.2107 9.24724 61.3696 9.68527C61.5324 10.1194 61.7708 10.4625 62.0848 10.7144C62.4026 10.9625 62.7922 11.0866 63.2535 11.0866Z"
        fill={color}
      />
    </svg>
  );
  return size === 'big' ? desktopSVG : mobileSVG;
};
