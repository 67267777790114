import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useMemo } from 'react';
import { NerdbordLogoIcon } from '../../components/icons/NerdbordLogoIcon';
import {
  NerdbordLogoText,
  TextColor,
} from '../../components/icons/NerdbordLogoText';

import { LogoWithBadge } from '../../components/LogoWithBadge/LogoWithBadge';
import { OpenAiLogo } from '../../components/OpenAiLogo/OpenAiLogo';
import styles from './Footer.module.scss';

interface FooterLink {
  href: string;
  title: string;
}

const Footer = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const platformLinksColumn1: FooterLink[] = useMemo(
    () => [
      {
        href: AppRoute.hackathons,
        title: 'Hackathons',
      },
      {
        href: AppRoute.home + '#for-whom',
        title: t('navbar.for-whom'),
      },
      { href: '#faq', title: t('navbar.faq') },
    ],
    []
  );

  const platformLinksColumn2: FooterLink[] = useMemo(
    () => [
      {
        href: AppRoute.home + '#why-participate',
        title: t('navbar.why-participate'),
      },
      { href: AppRoute.leaderboard, title: t('navbar.ranking') },
      { href: AppRoute.workshops, title: t('navbar.workshops') },
      {
        href: AppRoute.home + '#about-us',
        title: t('navbar.about-us'),
      },
    ],
    []
  );

  const resourcesLinks: FooterLink[] = useMemo(
    () => [
      {
        href: '/docs/privacy-policy.pdf',
        title: t('component.footer.documents.privacyPolicy'),
      },
      {
        href: '/docs/terms-conditions.pdf',
        title: t('component.footer.documents.termsOfService'),
      },
    ],
    []
  );

  return (
    <section className={styles.footer}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            {isDesktop && (
              <LogoWithBadge
                logoImagePath={
                  '/images/landing-v2/openAiLogoOrangeWhite.webp'
                }
                textColor={TextColor.Black}
              />
            )}
            {isMobile && (
              <div className={styles.mobileLogoWrapper}>
                <NerdbordLogoIcon size="big" />
                <span>
                  <NerdbordLogoText
                    size="big"
                    color={TextColor.Black}
                  />
                </span>
                <div>
                  <OpenAiLogo
                    imagePath={
                      '/images/landing-v2/openAiLogoOrangeWhite.webp'
                    }
                  />
                </div>
              </div>
            )}
            <p className={styles.subheader}>
              {t('component.footer.copyRight', {
                year: new Date().getFullYear(),
              })}
            </p>
          </div>

          <div className={styles.mobileWrapper}>
            <div className={styles.column}>
              <div className={styles.column}>
                {platformLinksColumn1.map((link, index) => (
                  <Link
                    key={index}
                    href={AppRoute.home + link.href}
                    className={styles.links}
                  >
                    {t(link.title)}
                  </Link>
                ))}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.column}>
                {platformLinksColumn2.map((link, index) => (
                  <Link key={index} href={link.href}>
                    <p className={styles.links}> {t(link.title)}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <p className={styles.subheader}>
              {t('component.footer.subheader.legals')}
            </p>
            <div className={styles.column}>
              {resourcesLinks.map((social, index) => (
                <a key={index} href={social.href}>
                  <p className={styles.links}>{social.title}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Footer;
