import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const logo = css({
  width: '124px',
  height: '24px',
  [mq(MediaQuery.Mobile)]: {
    width: '70px',
    height: '14px',
  },
});
