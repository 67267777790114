import Image from 'next/image';
import * as styles from './OpeAiLogo.styles'

interface OpenAiLogoProps {
  imagePath: string;
}

export const OpenAiLogo = ({ imagePath }: OpenAiLogoProps) => {
  return (
    <div>
      <Image
      className={styles.logo}
        src={imagePath}
        alt={'openAi Logo'}
        width={124}
        height={24}
      />
    </div>
  );
};
