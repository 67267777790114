import { AppRoute } from '@/config/routes';
import { usePageInfo } from '@/hooks/usePageInfo';
import Link from 'next/link';
import * as styles from './Navbar.styles';

type NavLinkProps = {
  title: string;
  href: string;
  onClick?: () => void;
};

const NavLink = ({ title, href, onClick }: NavLinkProps) => {
  const { isCurrentPathActive } = usePageInfo();
  const isLeaderboaradPage = isCurrentPathActive(
    AppRoute.leaderboard
  );

  return (
    <Link
      onClick={onClick}
      href={href}
      className={styles.navLink(isLeaderboaradPage)}
    >
      <span>{title}</span>
    </Link>
  );
};

export default NavLink;
