import { useRouter } from 'next/router';

export const usePageInfo = () => {
  const router = useRouter();

  const isCurrentPathActive = (path: string) =>
    router.asPath === path;

  return { isCurrentPathActive };
};
