interface NerdbordLogoIconProps {
  size: 'big' | 'small';
}
export const NerdbordLogoIcon = ({ size }: NerdbordLogoIconProps) => {
  const desktopSVG = (
    <svg
      width="52"
      height="31"
      viewBox="0 0 52 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5615 28.9233C11.5615 29.456 11.1269 29.8879 10.5908 29.8879H10.1095C4.74847 29.8879 0.402465 25.5689 0.402465 20.2412V19.6936C0.402465 19.1608 0.837066 18.7289 1.37317 18.7289H10.5908C11.1269 18.7289 11.5615 19.1608 11.5615 19.6936V28.9233Z"
        fill="#222225"
      />
      <path
        d="M11.5615 15.34C11.5615 15.8936 11.1269 16.3423 10.5908 16.3423H1.37317C0.837066 16.3423 0.402465 15.8936 0.402465 15.34V1.40304C0.402465 0.849503 0.837066 0.400772 1.37317 0.400772H10.5908C11.1269 0.400772 11.5615 0.849501 11.5615 1.40304V15.34Z"
        fill="#EA580C"
      />

      <path
        d="M13.9453 1.36556C13.9453 0.832779 14.3799 0.400879 14.916 0.400879H15.3973C20.7584 0.400879 25.1044 4.71989 25.1044 10.0477V10.5953C25.1044 11.128 24.6698 11.5599 24.1337 11.5599H14.916C14.3799 11.5599 13.9453 11.128 13.9453 10.5953V1.36556Z"
        fill="#222225"
      />
      <path
        d="M13.9453 14.9488C13.9453 14.3953 14.3799 13.9465 14.916 13.9465H24.1337C24.6698 13.9465 25.1044 14.3953 25.1044 14.9488V28.8858C25.1044 29.4393 24.6698 29.888 24.1337 29.888H14.916C14.3799 29.888 13.9453 29.4393 13.9453 28.8858V14.9488Z"
        fill="#EA580C"
      />
    </svg>
  );

  const mobileSVG = (
    <svg
      width="30"
      height="19"
      viewBox="0 0 30 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62891 17.4665C6.62891 17.772 6.37969 18.0197 6.07228 18.0197H5.7963C2.72212 18.0197 0.230006 15.543 0.230006 12.4879V12.1739C0.230006 11.8684 0.479217 11.6208 0.786635 11.6208H6.07228C6.37969 11.6208 6.62891 11.8684 6.62891 12.1739V17.4665Z"
        fill="#222225"
      />
      <path
        d="M6.62891 9.67747C6.62891 9.99488 6.37969 10.2522 6.07228 10.2522H0.786635C0.479218 10.2522 0.230006 9.99488 0.230006 9.67747V1.68564C0.230006 1.36822 0.479218 1.11091 0.786635 1.11091H6.07228C6.37969 1.11091 6.62891 1.36822 6.62891 1.68563V9.67747Z"
        fill="#EA580C"
      />

      <path
        d="M7.99609 1.66413C7.99609 1.35863 8.24531 1.11096 8.55272 1.11096H8.8287C11.9029 1.11096 14.395 3.5876 14.395 6.64268V6.95669C14.395 7.2622 14.1458 7.50986 13.8384 7.50986H8.55272C8.24531 7.50986 7.99609 7.2622 7.99609 6.95669V1.66413Z"
        fill="#222225"
      />
      <path
        d="M7.99609 9.45314C7.99609 9.13573 8.24531 8.87842 8.55272 8.87842H13.8384C14.1458 8.87842 14.395 9.13573 14.395 9.45314V17.445C14.395 17.7624 14.1458 18.0197 13.8384 18.0197H8.55272C8.24531 18.0197 7.99609 17.7624 7.99609 17.445V9.45314Z"
        fill="#EA580C"
      />
    </svg>
  );
  return size === 'big' ? desktopSVG : mobileSVG;
};
