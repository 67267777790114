import { breakpoints, MediaQuery } from '@/styles/mediaQueries';
import { useEffect, useState } from 'react';

export const useMediaQuery = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const desktopViewport = breakpoints.get(MediaQuery.DesktopSmall);

    setIsDesktop(
      typeof window !== 'undefined' && desktopViewport
        ? window.innerWidth >= desktopViewport
        : false
    );
  }, []);

  useEffect(() => {
    const tabletViewport = breakpoints.get(MediaQuery.TabletVertical);

    setIsTablet(
      typeof window !== 'undefined' && tabletViewport
        ? window.innerWidth <= tabletViewport
        : false
    );
  }, []);

  useEffect(() => {
    const mobileViewport = breakpoints.get(MediaQuery.Mobile);

    setIsMobile(
      typeof window !== 'undefined' && mobileViewport
        ? window.innerWidth <= mobileViewport
        : false
    );
  }, []);

  return {
    isDesktop,
    isMobile,
    isTablet,
  };
};
