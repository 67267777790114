import { Container } from '@/components/Container/Container';
import { AppRoute } from '@/config/routes';
import NavbarLinks from '@/ui/landing-v2/layout/Navbar/NavbarLinks';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { UserEntity } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { usePageInfo } from '../../../hooks/usePageInfo';
import ActionButton from '../../components/ActionButton/ActionButton';
import { TextColor } from '../../components/icons/NerdbordLogoText';
import { LogoWithBadge } from '../../components/LogoWithBadge/LogoWithBadge';
import * as styles from './Navbar.styles';

interface NavLink {
  href: string;
  title: string;
}

interface NavbarProps {
  user: UserEntity | null;
}

const Navbar = (props: NavbarProps) => {
  const { isCurrentPathActive } = usePageInfo();
  const isLeaderboaradPage = isCurrentPathActive(
    AppRoute.leaderboard
  );
  const isWorkshopsPage = isCurrentPathActive(AppRoute.workshops);
  const isHackathonsPage = isCurrentPathActive(AppRoute.hackathons);
  const [menuToggled, setmenuToggled] = useState(false);
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const router = useRouter();

  const navLinks: NavLink[] = useMemo(
    () => [
      {
        href: AppRoute.home + '#for-whom',
        title: t('navbar.for-whom'),
      },
      {
        href: AppRoute.home + '#reviews',
        title: t('navbar.reviews'),
      },
      { href: AppRoute.workshops, title: t('navbar.workshops') },
      { href: AppRoute.leaderboard, title: t('navbar.ranking') },
      { href: AppRoute.board, title: t('navbar.platform') },
    ],
    [t]
  );

  const logoPaths = {
    [AppRoute.leaderboard]: '/images/landing-v2/openAiLogoWhite.webp',
    [AppRoute.workshops]:
      '/images/landing-v2/openAiLogoOrangeWhite.webp',
    default: '/images/landing-v2/openAiLogoOrange.webp',
  };

  const openAiLogoPath =
    logoPaths[
      isCurrentPathActive(AppRoute.leaderboard)
        ? AppRoute.leaderboard
        : isCurrentPathActive(AppRoute.workshops)
        ? AppRoute.workshops
        : 'default'
    ];

  const mobileMenuToggle = () =>
    setmenuToggled((prevState) => !prevState);

  const mobileMenuHide = () => setmenuToggled(false);

  const handleJoinUs = () => {
    router.push(AppRoute.hackathons);
  };

  return (
    <header
      className={styles.header(
        isLeaderboaradPage,
        isWorkshopsPage || isHackathonsPage
      )}
    >
      <Container>
        <nav className={styles.navigation}>
          <div className={styles.logoWrapper}>
            <LogoWithBadge
              onClick={() => router.push(AppRoute.home)}
              logoImagePath={openAiLogoPath}
              textColor={
                isCurrentPathActive(AppRoute.leaderboard)
                  ? TextColor.White
                  : TextColor.Black
              }
            />
          </div>

          <div className={styles.navLinksWrapper(menuToggled)}>
            {navLinks.map((link, index) => (
              <NavbarLinks
                key={index}
                onClick={mobileMenuHide}
                href={link.href}
                title={link.title}
              />
            ))}
            {!isHackathonsPage && (
              <ActionButton onClick={handleJoinUs} variant="primary">
                {t('navbar.button.browse')}
              </ActionButton>
            )}
          </div>
          <div className={styles.mobileButtonsWrapper}>
            <div className={styles.hamburgerMenu}>
              <ActionButton
                variant="secondary"
                onClick={mobileMenuToggle}
              >
                Menu
              </ActionButton>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Navbar;
